import React from 'react'

const NotFound = () => {
  return (
    <div className='container-home not-found'>
      <h2>ERROR 404:</h2>
      <h3>Page Not Found</h3>
    </div>
  )
}

export default NotFound
