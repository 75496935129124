import React, { useContext } from 'react'
import { WalletContext } from '../Providers/WallectConnect'
import { FiLogOut } from "react-icons/fi";

const ConnectNav = () => {
    const { Provider, address,connectToWallet,disconnectWallet } = useContext(WalletContext)
  return (
    <>
    {
        !address ?
        <button onClick={()=>connectToWallet()} className='btn btn-connect'>Connect your wallet</button>
        : <p className='menu-nav-social-address'>{address.substr(0,8)}...{address.substr(-8,8)} <FiLogOut onClick={()=>disconnectWallet()} className='logout' size={"20px"}/></p>
    }
      
    </>
  )
}

export default ConnectNav
