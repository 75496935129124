import React from 'react'
import { Link } from 'react-router-dom';

const Home = () => {

    const handleVideoEnd = (event) => {
        event.target.play();
    };

  return (
    <div className='container-home'>
      <h2 className='container-home-titleG'>G PASS</h2>
      <h3>Direct Access to Signal</h3>
      <div className='container-home-card'>
        <video controls muted autoPlay onEnded={handleVideoEnd} controlsList="nodownload">
            <source src="https://shiller.s3.amazonaws.com/gpass.mp4" type="video/mp4" />
            Tu navegador no soporta el elemento de video.
        </video>
      {/* <iframe class="elementor-video-iframe" allowfullscreen="" title="vimeo Video Player" src="https://rr3---sn-x1x7dnez.c.drive.google.com/videoplayback?expire=1700172883&ei=I2pWZc7XEtbB1bYP-Yuk2AY&ip=2800:22a0:4000:7b6:b179:bcc1:2c1a:b32c&id=79de6d3e82a6f028&itag=22&source=webdrive&requiressl=yes&xpc=EghonaK1InoBAQ==&mh=nk&mm=32&mn=sn-x1x7dnez&ms=su&mv=m&mvi=3&pl=40&ttl=transient&susc=dr&driveid=1mmecUuJS-HqX9HEg9KO-cHv4henLyKm3&app=explorer&eaua=FeBO4f3VXs4&mime=video/mp4&vprv=1&prv=1&dur=28.258&lmt=1700161639936458&mt=1700161723&subapp=DRIVE_WEB_FILE_VIEWER&txp=0006224&sparams=expire,ei,ip,id,itag,source,requiressl,xpc,ttl,susc,driveid,app,eaua,mime,vprv,prv,dur,lmt&sig=ANLwegAwRgIhAMP7yhsD-rdDnw7XmiulK882wENr9swsGfBdQs-qIWjLAiEAxHot4lKHLjeAVIBdTgfsjKENs5AgVet3ed9mul_6hyU=&lsparams=mh,mm,mn,ms,mv,mvi,pl&lsig=AM8Gb2swRQIhAJrjhMwE1U2Xj4a9NBFLGbdF19os5QyvA84TkxKheWd_AiAP4VCe-62EsHzmqgQtFw405hYx2dKjigAlzqiszrASEQ==&cpn=vj38WyS8fpbBb6X5&c=WEB_EMBEDDED_PLAYER&cver=1.20231112.00.00"></iframe> */}
      </div>
      <Link to={"/mint"}> <button className='btn'>Mint Now</button></Link>
    </div>
  )
}

export default Home