import React from 'react'
import shillerLogo from "../Assets/shiller-icon.png"
import {FaXTwitter,FaInstagram,FaLinkedin } from "react-icons/fa6"

const Footer = () => {
  return (
    <>
    <div className='container-footer'>
        <div className='container-footer-logo'>
            <img className='logo-footer' src={shillerLogo} alt="Shiller Logo" />
            <div className='container-footer-logo-info'>
                <h2>SHILLER, INC</h2>
                <p>304 S. Jones Blvd, Suite 2614,</p>
                <p>Las Vegas, NV 89107</p>
            </div>
        </div>
        <div className='container-footer-menu'>
            <div className='container-footer-menu-left'>
                <ul>
                    <li><a href="https://shiller.io/getting-started/" target='_blank' rel="noreferrer">Getting Started</a></li>
                    <li><a href="https://shiller.io/shiller-tips/" target='_blank' rel="noreferrer">Shiller Tips</a></li>
                    <li><a href="https://shiller.io/faqs/" target='_blank' rel="noreferrer">Faqs </a ></li>
                    <li><a href="https://shiller.io/contact/" target='_blank' rel="noreferrer">Contact</a></li>
                </ul>
                <ul>
                    <li><a href="https://shiller.io/community/" rel="noreferrer">Community Guidelines</a></li>
                    <li><a href="https://shiller.io/privacy-policy/" rel="noreferrer">Privacy Policy</a></li>
                    <li><a href="https://shiller.io/press/" rel="noreferrer">Press Enquiries</a></li>
                </ul>
                
            </div>
            <div className='container-footer-menu-right'>
                <div className='container-footer-menu-left-icons'>
                    <a href="https://twitter.com/shiller" target='_blank' rel="noreferrer"><FaXTwitter size={"27px"}/></a>
                    <a href="https://www.instagram.com/shiller_io/" target='_blank' rel="noreferrer"><FaInstagram size={"27px"}/></a>
                    <a href="https://www.linkedin.com/company/shiller/" target='_blank' rel="noreferrer"><FaLinkedin size={"27px"}/></a>
                </div>
                
            </div>
        </div>

    </div>
        <p className='copy'>Copyright © Shiller, 2023. This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
    </>
  )
}

export default Footer