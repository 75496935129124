import React, { useEffect, useState } from 'react'
import {FaXTwitter,FaInstagram,FaTiktok,FaShareNodes } from "react-icons/fa6"
import Nav from './Nav'
import logo from "../Assets/shiller-logo.svg"
import { Link, useLocation } from 'react-router-dom'
import ConnectNav from './ConnectNav'


const MenuNav = () => {

    const [isOpen,setIsOpen] = useState(false)
    const [scrolling, setScrolling] = useState(false);
    const location = useLocation();

    const toggleIsOpen = ()=>{
        setIsOpen(!isOpen)
    }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={`menu-nav ${scrolling ? 'scrolling-header' : ''}`}>
        <div className='menu-nav-options'>
            <Link to={"/"}><img className='shiller-logo' src={logo} alt="Shiller Logo" /></Link>
            <div className={`menu-nav-options-hambur ${isOpen ? "active" : ""} `} onClick={()=>setIsOpen(!isOpen)}>
                <div className='menu-nav-options-hambur-line line-1'></div>
                <div className='menu-nav-options-hambur-line line-2'></div>
                <div className='menu-nav-options-hambur-line line-3'></div>
            </div>
        </div>
        <div className='menu-nav-social'>
            <Nav sty={"menu-nav-options"}/>
            <div className='menu-nav-social-icons'>
                <a href="https://twitter.com/shiller" target='_blank' rel="noreferrer"><FaXTwitter size={"22px"} className='menu-nav-social-icons-img'/></a>
                <a href="https://www.instagram.com/shiller_io/" target='_blank' rel="noreferrer"><FaInstagram size={"22px"} className='menu-nav-social-icons-img'/></a>
                <a href="https://www.tiktok.com/@shiller" target='_blank' rel="noreferrer"><FaTiktok size={"22px"} className='menu-nav-social-icons-img'/></a>
                <a href="https://linktr.ee/shiller.io" target='_blank' rel="noreferrer"><FaShareNodes size={"22px"} className='menu-nav-social-icons-img'/></a>
            </div>
            {location.pathname === '/mint' || location.pathname ==='/claim' && <ConnectNav />}
        </div>
        {
            isOpen &&
            <div className={`menu-mobile ${isOpen ? "aparecer" : ""}`}>
                <Nav sty={"options-list-mobile "} toggle={toggleIsOpen}/>
                <div className='menu-mobile-nav-social'>
                    <div className='menu-mobile-nav-social-icons'>
                        <a href="https://twitter.com/shiller" target='_blank' rel="noreferrer"><FaXTwitter size={"22px"} className='menu-nav-social-icons-img'/></a>
                        <a href="https://www.instagram.com/shiller_io/" target='_blank' rel="noreferrer"><FaInstagram size={"22px"} className='menu-nav-social-icons-img'/></a>
                        <a href="https://www.tiktok.com/@shiller" target='_blank' rel="noreferrer"><FaTiktok size={"22px"} className='menu-nav-social-icons-img'/></a>
                        <a href="https://linktr.ee/shiller.io" target='_blank' rel="noreferrer"><FaShareNodes size={"22px"} className='menu-nav-social-icons-img'/></a>
                    </div>
                    
                </div>
                {location.pathname === '/mint' || location.pathname ==='/claim' && <ConnectNav />}
            </div>
        }
    </div>
  )
}

export default MenuNav