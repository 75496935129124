import React from 'react'
import { Link } from 'react-router-dom'

const Nav = ({sty,toggle}) => {
  return (
    <ul className={`${sty}`}>
        <li className='menu-nav-options-list-item options-list-mobile-item'><a href="https://shiller.io/" target='_blank' rel="noreferrer">Home</a> </li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><a href="https://shiller.io/#apply" target='_blank' rel="noreferrer">Apply</a> </li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><a href="https://shiller.io/press/" target='_blank' rel="noreferrer">Press</a> </li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><a href="https://shiller.io/team/" target='_blank' rel="noreferrer">Team</a> </li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><a href="https://shiller.io/contact/" target='_blank' rel="noreferrer">Contact</a> </li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><Link  to={"/mint"} onClick={toggle}>Mint</Link></li>
        <li className='menu-nav-options-list-item options-list-mobile-item'><Link  to={"/claim"} onClick={toggle}>Claim</Link></li>
    </ul>
  )
}

export default Nav
