import React from 'react'

const Loader = () => {
  return (
    <div className='loading'>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 398 796"
        style={{ enableBackground: "new 0 0 398 796" }}
        xmlSpace="preserve"
        className='logo2'
        >
        <style
            type="text/css"
            dangerouslySetInnerHTML={{ __html: "\t.st0{fill:#FFFFFF;}" }}
        />
        <g>
            {" "}
            <path
            className="st0"
            d="M398,280.9l-132.7,46.8v-93.6l-132.7-93.6V46.8L265.3,0v93.6L398,187.3V280.9L398,280.9z M0,608.7l132.7,93.6  V796l132.7-46.8v-93.6l-132.7-93.6v-93.6L0,515.1V608.7L0,608.7z M398,468.2L132.7,280.9V140.5L0,187.3v140.5l265.3,187.3v140.5  L398,608.7V468.2L398,468.2z"
            />
        </g>
       </svg>

    </div>
  )
}

export default Loader
