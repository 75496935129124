import React, { useState } from 'react'
import gpass from "../../Assets/g-pass.png"
import ppass from "../../Assets/ppass.png"
import { Link } from 'react-router-dom';

const Mint = () => {
    const handleVideoEnd = (event) => {
        event.target.play();
    };

    const [stateG,setStateG] = useState(false)

  return (
    <div className='container-home home'>
      <h2 className='container-home-title'>MINT YOUR PASS:</h2>
      <div className='container-home-pass'>
        <div className='container-home-pass-item' onMouseEnter={()=>{setStateG(true)}} onMouseLeave={()=>{setStateG(false)}}>
            <div className='container-home-pass-item-art' >
                {
                    !stateG ? <img src={gpass} alt="GPASS SHILLER" />
                    : <video muted autoPlay onEnded={handleVideoEnd} controlsList="nodownload" className='aparecer2'>
                        <source src="https://shiller.s3.amazonaws.com/gpass.mp4" type="video/mp4" />
                         Tu navegador no soporta el elemento de video.
                      </video>
                }
            </div>
            <div className='container-home-pass-item-text'>
                <div>
                    <h3 className='container-home-pass-item-text-title'>G PASS</h3>
                    <h4>Direct Access to Signal</h4>
                </div>
                <p>The G Pass is your ticket to live, interactive audio and video content on the Shiller app.</p>
                <Link to={"/gpass"}><button className='btn btn-2'>Mint Now</button></Link>
            </div>
        </div>
        <div className='container-home-pass-item'>
            <div className='container-home-pass-item-art'>
                <img src={ppass} className='ppass-img' alt="GPASS SHILLER" />
            </div>
            <div className='container-home-pass-item-text'>
                <div>
                    <h3 className='container-home-pass-item-text-title'>P PASS</h3>
                    <h4>COMING SOON</h4>
                </div>
                <p>Ownership of a P Pass will grant access to exclusive, live audio and video broadcasts on the Shiller platform.</p>
                <Link to={"/ppass"}><button className='btn btn-2' disabled>Coming Soon</button></Link>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Mint
