import React, { useContext, useEffect, useState } from 'react'
import {FaEthereum} from "react-icons/fa6"
import gpass from "../../Assets/g-pass.png"
import succesImg from "../../Assets/sucess-pass.png"
import { SiOpensea } from "react-icons/si";
import looksrare from "../../Assets/looksrare.png"
import rarible from "../../Assets/rarible.png"
import alchemy from "../../Assets/alchemy.png"
import { ethers } from 'ethers';
import { WalletContext } from '../../Providers/WallectConnect';
import Abi from '../../artifacts/contracts/Airdrop.sol/Airdrop.json';
import Swal from 'sweetalert2';
import Loader from '../../Components/Loader';


const Claim = () => {
    const { Provider, address,connectToWallet } = useContext(WalletContext)
    let ContractAddress="0x1A1E0C1cb208479a9D3E45A4A7473455B3172Ea2";
    let contract = new ethers.Contract(ContractAddress, Abi.abi, Provider)
    useEffect(()=>{
        if(address){
        GetPrice();
        }
    },[address])
    const [loading,setLoading] = useState(false)
    const [available,setAvailable] = useState("0")
    const [amountEth, setAmountEth] = useState("0")
    const [amount, setAmount] = useState("1")
    const [state,setState] = useState("mint")
    const [yourNfts,setYourNfts] = useState(0)

    async function GetPrice(){
        contract.UserGift(address).then(r=>setAvailable(r.toString()))
    }

    async function PreSale(){
    let signer = Provider.getSigner()
   //setLoading(true)
    let contract2 = new ethers.Contract(ContractAddress, Abi.abi, signer)
    const gasPrice = await Provider.getGasPrice();
    contract2
      .GetNFt({gasPrice: gasPrice})
      .then(async (r) => {
        setLoading(true)
        r.wait()
          .then((r) => {
            GetPrice();
            Swal.fire({
                icon:"success",
                title:"Claim successful"
            })
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      })
      .catch((e) => {
        setLoading(false)
        const errorMessage = e.error.message
        Swal.fire({
          icon: 'error',
          title: errorMessage,
        })
      })
    }

  return (
    <div className='container-mint'>
        {loading &&
            <Loader />
        }
      <h2>G PASS</h2>
      <h3>Direct Access to Signal</h3>
      <div className='container-mint-card'>
        { state === "mint" &&
            <>
                <div className='container-mint-card-left'>
                    <img className='container-mint-card-left-img' src={gpass} alt="G Pass" />
                </div>
                <div className='container-mint-card-right'>
                    <div className='container-mint-card-right-tittle'>
                        <h4>Claim Your G Pass</h4>
                        <p>The G Pass is your ticket to live, interactive audio and video content on the Shiller app. All holders can join regular broadcasts, as well as play back any previous content.</p>
                    </div>
                    <div className='container-mint-card-right-amount'>
                        <div className='container-mint-card-right-amount-button'>
                           {address
                           ?available>0
                           ?<button className='btn btn-2' onClick={()=>PreSale()}>Claim</button>
                           :<p>you already claimed your nft or do not have an assigned nft</p>
                           :<button className='btn btn-2' onClick={()=>connectToWallet()}>Connect Wallet</button>
                        }
                        </div>
                        <div className='container-mint-card-right-amount-available'>
                            <p className='text-available'>{available} Available</p>
                        </div>
                    </div>
                </div>
            </>
        }
        { state === "success" &&
            <>
                <div className='container-mint-card-left'>
                    <img className='container-mint-card-left-img' src={succesImg} alt="G Pass" />
                </div>
                <div className='container-mint-card-right card-right-success'>
                    <div className='container-mint-card-right-tittle'>
                        <h4>SUCCESS!</h4>
                        <p>Your G Pass mint is complete.</p>
                    </div>
                    <div className='container-mint-card-right-amount'>
                        <div className='container-mint-card-right-amount-success'>
                            <p className=''>View your pass.</p>
                        </div>
                        <ul className='container-mint-card-right-amount-optionsSuccess'>
                            <li><a href="" target='_blank'><SiOpensea  size={"25px"}/></a></li>
                            <li><a href="" target='_blank'><img src={rarible} className='social-icon' alt='Looksrare icon' /></a></li>
                            <li><a href="" target='_blank'><img src={looksrare} className='social-icon' alt='Looksrare icon' /></a></li>
                            <li><a href="" target='_blank'></a> <img src={alchemy} className='social-icon' alt='Looksrare icon' /></li>
                            <li><a href="" target='_blank'><FaEthereum size={"22px"} /></a> </li>
                        </ul>
                        <div className='container-mint-card-right-amount-button'>
                            <button className='btn btn-2' onClick={()=>setState("mint")}>Claim</button>
                        </div>
                    </div>
                </div>
            </>
        }
      </div>
    </div>
  )
}

export default Claim
