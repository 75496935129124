import logo from './logo.svg';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Gpass from './Pages/Mint/Gpass';
import MenuNav from './Components/MenuNav';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer';
import Claim from './Pages/Claim/Claim';
import Mint from './Pages/Mint/Mint';
import Ppass from './Pages/Mint/Ppass';
import NotFound from './Components/NotFound';

function App() {
  return (
    <div className="container-main">
      <MenuNav />
      <Routes>
        <Route path='/' element={<Mint />}/>
        <Route path='/mint' element={<Mint />}/>
        <Route path='/gpass' element={<Gpass />}/>
        <Route path='*' element={<NotFound />}/>
        {/* <Route path='/ppass' element={<Ppass />}/> */}
        <Route path='/claim' element={<Claim />}/>
      </Routes>
      <Footer />
      
    </div>
  );
}

export default App;
